import * as React from 'react';
import { ITag } from '../requests/createTag';
import TagPost from '../components/TagPost/TagPost';

const TagPageTemplate = ({
  location,
  pageContext,
}: {
  pageContext: ITag;
  location: any;
}) => {
  return <TagPost {...pageContext} location={location} />;
};

export default TagPageTemplate;
