import * as React from 'react';
import { marked } from 'marked';
import CodeBlock from '../CodeBlock';
import ContentSnippet from '../ContentSnippet';
import Page from '../Page';
import Row from '../Row';
import Section from '../Section';
import Tag from '../Tag';
import { Body, Heading, TextWrapper } from '../Text';
import ContentImage from '../ContentImage/ContentImage';
import { ITag } from '../../requests/createTag';
import { IPost } from '../../requests/findPosts';
import PostList from '../PostList/PostList';

interface TagPostProps extends ITag {
  location: any;
}

const TagPost = ({
  location,
  name,
  uuid,
  posts,
  resources,
  contentSnippets,
}: TagPostProps) => {
  return (
    <Page
      location={location}
      title={name}
      description={`A breif overview of ${name}`}
      keywords={[name]}
    >
      <Section>
        <Heading.h1>{name}</Heading.h1>
      </Section>
      {contentSnippets.map(({ title, content, uuid, images }) => (
        <React.Fragment key={uuid}>
          <ContentSnippet
            title={title}
            content={marked.parse(content)}
            images={images}
          />
        </React.Fragment>
      ))}
      {resources.length > 0 && (
        <Section>
          <Heading.h3>Further resources</Heading.h3>
          <Row flexDirection="column" rowGap={6}>
            {resources.map(({ title: resourceTitle, href, uuid }) => (
              <Body.a external key={uuid} href={href}>
                {resourceTitle}
              </Body.a>
            ))}
          </Row>
        </Section>
      )}
      {posts.length > 0 && (
        <Section>
          <Heading.h3>Related topics</Heading.h3>
          <PostList posts={posts} />
        </Section>
      )}
    </Page>
  );
};

export default TagPost;
